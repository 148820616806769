<template>
    <div>
        <div class="page-header d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <router-link :to="{ name: 'marketing_resource' }">
              <button class="btn btn-control">
                <img
                  alt="Return back"
                  src="@/assets/icons/bold-icon previous.svg"
                />
              </button>
            </router-link>
            <h3 class="page-header-title ms-2">Assets Creation</h3>
          </div>
        </div>
        <div class="page-content p-0">
            <iframe
                id="JotFormIFrame-221227227486153"
                title="Clear Mortgage Capital Assets Creation Form"
                onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                allowfullscreen="true"
                src="https://form.jotform.com/221227227486153"
                frameborder="0"
                style="min-width: 100%; border:none;" scrolling="no">
            </iframe>
        </div>
    </div>
</template>
<script>
export default {
  name: 'AssetsCreation',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
#JotFormIFrame-221227227486153 {
  height: 107rem !important;
  display: block;
}
</style>
